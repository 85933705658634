import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';

let listId = 0;

interface Item {
	[key: string]: any;
}

@autoinject
export class BelAuAutoCompleteCustomElement {
	@bindable({ defaultBindingMode: bindingMode.twoWay }) value?: Item | string;
	@bindable({ defaultBindingMode: bindingMode.toView }) content?: Item[] | string[];
	@bindable({ defaultBindingMode: bindingMode.toView }) size = 1;
	@bindable({ defaultBindingMode: bindingMode.toView }) placeholder?: string = "";
	@bindable({ defaultBindingMode: bindingMode.toView }) required?: boolean = false;
	@bindable({ defaultBindingMode: bindingMode.toView }) disabled?: boolean = false;
	@bindable({ defaultBindingMode: bindingMode.toView }) choose = (param: { value: string }) => {/* no warn */ };
	@bindable({ defaultBindingMode: bindingMode.toView }) focused = (param: {}) => { /* no warn */ };
	@bindable({ defaultBindingMode: bindingMode.oneTime }) labelKey = "name";

	private listId: string;

	constructor(private element: Element) {
		this.listId = "list" + ++listId;
	}

	onInput() {
		let value = this.element.getElementsByTagName("input")[0].value;
		if (this.content) {
			// @ts-ignore:next-line
			let found = this.content.find(choice => value === this.choiceAsString(choice));
			if (found) {
				this.choose({ value });
			}
		}
	}

	focusStart() {
		let value = this.element.getElementsByTagName("input")[0].value;
		this.focused({ value });
	}

	@computedFrom("value")
	get choiceValue() {
		return this.choiceAsString(this.value);
	}

	set choiceValue(value: string) {
		/* noop */
	}

	choiceAsString(choice?: Item | string) {
		if (!choice) {
			return "";
		}
		return typeof choice === "string" ? choice : choice[this.labelKey]
	}

	@computedFrom("content")
	get contentAsStrings(){
		return this.content?.map(i => {
			return this.choiceAsString(i);
		})
	}

	focusEnd() {
		let value = this.element.getElementsByTagName("input")[0].value;
		if (this.content && value) {
			for (let choice of this.content) {
				const choiceStringValue = this.choiceAsString(choice);
				if (choiceStringValue.toLowerCase() === value.toLowerCase()) {
					value = choiceStringValue;
					this.value = choice;
					break;
				}
				if (choiceStringValue.startsWith(value)) {
					value = choiceStringValue;
					this.value = choice;
				}
			}
		}
		this.choose({ value });
	}

	attached() {
		if (this.content) {
			this.content.forEach(c => {
				if (!this.size || this.size < c.length && c.length > 1) {
					this.size = c.length;
				}
			});
		}
	}
}
